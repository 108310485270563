
import './css/styles.scss';
import typewriter from 'typewriter-effect/dist/core'
import gsap from 'gsap'
import { useEffect } from 'react';

function App() {

  useEffect(()=>{

   

    var type = document.querySelector('.type')
    var orange_border = document.querySelector('.border')
    var temp_logo = document.querySelector('.temp_logo')
    var logo = document.querySelector('.logo')
    var contact_details1 = document.querySelector('.contact_details div:nth-child(1)')
    var contact_details2 = document.querySelector('.contact_details div:nth-child(2)')
    var contact_details3 = document.querySelector('.contact_details div:nth-child(3)')
    var watermark = document.querySelector('.watermark')
    var timeline = gsap.timeline()
    
    var type_writer = new typewriter(type, {
      strings: ['We are thrilled to be hard at work constructing a virtual space for you.','Prepare for a transcending  journey beyond the ordinary.'],
      autoStart: true,
      loop:true,
      delay:80
    });

    type_writer.stop()

    gsap.set([logo,contact_details1,contact_details2,contact_details3],{
      y:50
    })

    timeline.to(temp_logo,{
      delay:1.5,
      alpha:0,
      duration:1,
      ease:'power4.out',
      onComplete:()=>{
        temp_logo.style.display='none'
      }
    })
  
    timeline.to(orange_border,{
      width:'95%',
      height:'95%',
      alpha:1,
      duration:2,
      ease:'power4.out',
      onComplete:()=>{
        type.style.display='block'

        var mobile_view = window.matchMedia('(max-width:650px)')

        if(mobile_view.matches){
          gsap.to(watermark,{
            alpha:0.05
          })
        }

        setTimeout(function(){
          type_writer.start()
        },2000)
        
      }
      
    },'-=1.5')

    timeline.to([logo,contact_details1,contact_details2,contact_details3],{
      alpha:1,
      stagger:.2,
      y:0
    })

  })




  return (
    <div className="App">
      <img src='./images/logo.jpeg' alt='structconprojekts logo' className='temp_logo'/>
      <img src='./images/logo.jpeg' alt='structconprojekts logo' className='logo'/>
      <p className='type'></p>
      <div className='watermark'>
        <img src='./images/icon.jpg' alt='structconprojekts icon'/>
      </div>
      <div className='border'></div>
      <div className='contact_details'>
        <div>
          <p>Westland Blvd Avenue, Accra</p>
          <p>GE-328-9260</p>
        </div>

        <div>
          <p>info@structconprojekts.com</p>
        </div>

        <div>
          <p>+233 265 207 324</p>
          <p>+233 302 444 296</p>
        </div>

        
      </div>
    </div>
  );
}

export default App;
